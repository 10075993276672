import { Component, Vue, Model ,Watch} from "vue-property-decorator";
import { phone, pas, phoneCode ,referrerCode} from "@/utils/rule";
import backHead from "@/components/backHead";
import * as registerbox from "@/utils/api";
import axios, { AxiosRequestConfig } from "axios";

@Component({
  components: {
    backHead
  }
})

export default class RegisterModel extends Vue {
  checked = false;
  /**
   * 定义当前数据
   */
  msg = "";
  // 注册按钮显示时机
  btnShow=false;
  show = false;
  tip = false;
  // 注册成功弹窗
  registerSuccess = false;

  private RegisterForm = {
    phone: "",
    password: "",
    referrerCode: localStorage.getItem("referrerCode")
      ? localStorage.getItem("referrerCode")
      : "",
    captcha: "",
    platform: "MOBILE_PWA",
    regLat: 0,
    regLng: 0
  };

  private tipForm = {
    title:'',
    content:'',
    show:false
  }
  // 倒计时初始化
  private phonetime = {
    time: "获取验证码",
    btndisable: false
  };
  // 推荐码
  private  referral = {
    title: "",
    btndisable: false
  };


  /**
   * 定义当前表单验证
   */
  private loginRules = {
    phone: [{ required: true, trigger: "blur", validator: phone[0].validator }],
    referrerCode:[{trigger: "blur", validator: referrerCode[0].validator }],
    captcha: [
      { required: true, trigger: "blur", validator: phoneCode[0].validator }
    ],
    password: [{ required: true, trigger: "blur", validator: pas[0].validator }]
  };

  mounted() {
    registerbox.agreementApi("REGISTER").then((res:any) => {

      this.msg = res.content;
    });
  }
  @Watch('RegisterForm', { deep: true })
  onTypeChange(newVal:any, oldVal:any) {
    if (newVal.captcha && newVal.phone && newVal.password) {
      (this.$refs.RegisterForm as any).validate((valid: boolean) => {
        if (valid) {
              this.btnShow = valid
        }else{
        this.btnShow = false
        }
      });
    }
  }
  iKnow(){
    const headers_ = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
    const auth = {
      username: JSON.parse(process.env.VUE_APP_MY_PARMA).username,
      password: JSON.parse(process.env.VUE_APP_MY_PARMA).password
    };
    const transformRequest = [
      function(data: { [x: string]: string | number | boolean }) {
        let ret = ""
        for (const it in data) {
          ret +=
            encodeURIComponent(it) +
            "=" +
            encodeURIComponent(data[it]) +
            "&"
        }
        return ret;
      }
    ];
    let userName:any = JSON.parse(localStorage.getItem('phone')!)
    let password:any = JSON.parse(localStorage.getItem('password')!)

    registerbox.login( {
      username: userName += "|MOBILE_PWA",
      password: password,
      scope: "write",
      grant_type: "password",
      client_secret: JSON.parse(process.env.VUE_APP_MY_PARMA).password,
      client_id: JSON.parse(process.env.VUE_APP_MY_PARMA).username
    }, {auth, transformRequest,headers_ })
      .then((res:any) => {
        if (res.access_token) {
          localStorage.setItem("token", res.access_token)
          localStorage.setItem("refresh_token", res.refresh_token)
          localStorage.setItem("isSevrice",'1')
          const userName = String(this.RegisterForm.phone)
          localStorage.setItem("moible", userName)
          // localStorage.setItem("sela",getuser.getuser().then(res => {res.uid}));
          // localStorage.setItem("sela1","粤恒通（北京天雅）检测中心");
          // localStorage.setItem("sela2" ,"北京市东城区 法华寺街136号天雅珠宝城1楼1076");
          this.registerSuccess = false
          this.$router.push({
            name:'certification'
          })
        }
      })
      .catch((err:any) => {
        this.$toast.fail("出错啦!请稍后再试...")
      })
  }
  check(){
    console.log('1')

    //  if (this.RegisterForm.captcha && this.RegisterForm.phone && this.RegisterForm.referrerCode && this.RegisterForm.password && this.checked) {
    //   (this.$refs.RegisterForm as any).validate((valid: boolean) => {
    //     if (valid) {
    //           this.btnShow = valid
    //     }else{
    //     this.btnShow = false
    //     }
    //   })
    //  }else{
    //   this.btnShow = false
    //  }
  }
  showrule() {
    this.show = true;
  }
  // 点击登录
  loginbtn() {
    this.$router.push({ name: "login" });
  }
  // 获取验证码
  getCode(formName:any) {
    (this.$refs[formName] as any).validateField("phone", (phoneError:string) => {
      //验证手机号码是否正确
      if (!phoneError) {
        // 手机号码正确
        if (!this.phonetime.btndisable) {
          this.phonetime.btndisable = true;
          let lastTime = 60;
          const timer = setInterval(() => {
            if (lastTime > 0) {
              this.phonetime.time = lastTime + "s";
              lastTime--;
            } else {
              window.clearInterval(timer);
              this.phonetime.btndisable = false;
              this.phonetime.time = "获取验证码";
            }
          }, 1000);
          registerbox
            .getPhoneCode(this.RegisterForm.phone, "SIGNIN")
            .then((res:any) => {
              if (res.code != 0) {
                this.$toast.fail(res.msg);
                window.clearInterval(timer);
                this.phonetime.btndisable = false;
                this.phonetime.time = "获取验证码";
              }
            }).catch((err:any)=>{
              window.clearInterval(timer);
              this.phonetime.btndisable = false;
              this.phonetime.time = "获取验证码";
            });
        }
      } else {
        this.tip = true
        this.tipForm.title = phoneError
        this.tipForm.show = false
        return false;
      }
    });
  }
  // 推荐码提示框
  getReferrerCode(){
    this.tip = true
    this.tipForm.title = '什么是推荐码'
    this.tipForm.content= '推荐码是已注册用户邀请好友注册的唯一标识，注册填写将xxxxxxxxxx'
    this.tipForm.show = true
  }
  handleRegister(formName:any) {

    if(!this.btnShow){

        return false
    }
    console.log(this.checked);

    if (!this.checked) {
      this.$toast(`需先同意《${Vue.prototype.GLOBAL}用户注册协议》及《${Vue.prototype.GLOBAL}用户隐私政策》`);
      return false
    }

    (this.$refs[formName] as any).validate((valid: boolean) => {
        if (valid) {
          const payload = JSON.parse(JSON.stringify(this.RegisterForm))
          if (!payload.referrerCode) {
            payload.referrerCode = "fz8ff"
          }
          registerbox.account(payload).then((res:any) => {
                localStorage.setItem('phone',this.RegisterForm.phone)
                localStorage.setItem('password',this.RegisterForm.password)
                this.registerSuccess = true
          });
        }
      });
    }
}
